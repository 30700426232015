<template>
    <div>
        <myHead/>
        <div>
            <div hoverable  class="queryDiv">
                    <div style="float:left">
                         <a-tag color="#f082ac">注意</a-tag>
                        平台只保留一个月的数据，请及时核对财务数据，过时删除，无法对账，有需要请备份任务和资金明细。
                    </div>
            </div>
            <a-row  style="width:100%;padding-top:00px;padding-left:20px;padding-right:50px">
                <a-col flex="auto">
                    <a-table 
                        
                        :columns="columns"  
                        :loading="isLoading"
                        :data-source="announcementList"
                        @change="handleTableChange"
                        :pagination="pagination">
                                    <template #title="{ text }">
                                        <span style="font-weight:500">
                                            {{text}}
                                        </span>
                                    </template>
                                    <template #content="{ text }">
                                        <span style="font-weight:500">
                                            {{text}}
                                        </span>
                                    </template>
                                    <template #createTime="{ text }">
                                        <a>
                                            {{text}}
                                        </a>
                                    </template>
                    </a-table>
                </a-col>
            </a-row>
        </div>
           <myFooter/>
    </div>
</template>
<script>
import { } from '@ant-design/icons-vue';
// import { message } from 'ant-design-vue';
 
 
import myHead from '../components/myHead'
import myFooter from '../components/myFooter'
//  import myPreview from '../components/myPreview'

import {  shopGetSel,uri,memberGet,announcementGet} from '@/request.js'


export default {
    
    components:{myHead,myFooter},
    data() {
        return {
            member:{},
            shopList:[],
            queryModel:{
                queryTxt:"",
                createTimes:undefined,
                shopId:undefined,
                type:undefined,
            },
            announcement:[],
            announcementList:[],
            isLoading:false,
            pagination: {
                total: 0,
                pageSize: 8,//每页中显示10条数据
                showSizeChanger: true,
                pageSizeOptions: ["8", "16", "32", "64"],//每页中显示的数据
                showTotal: total => `共 ${total} 条`,  //分页中显示总的数据
                onShowSizeChange: (current, pageSize) => {
                    this.pagination.current = 1;
                    this.pagination.pageSize = pageSize;
                    this.announcementGetAction(1); //显示列表的接口名称
                },
            },
            columns:[
                {
                    title: '标题',
                    dataIndex: 'Title',
                    key: 'Title',
                    slots: { customRender: 'title' },
                },
                {
                    title: '内容',
                    dataIndex: 'Context',
                    key: 'Context',
                    slots: { customRender: 'context' },
                },
                {
                    title: '发布时间',
                    dataIndex: 'CreateTime',
                    key: 'CreateTime',
                    slots: { customRender: 'createTime' },
                },
            ],
        };
    },
    methods: {
        query(){
            this.pagination.current = 1;
             this.announcementGetAction(1);
        },
        exportAction(){
            let array=new Array();
            if(this.queryModel.createTimes!==undefined&&this.queryModel.createTimes.length>0){
                for(var i=0;i<this.queryModel.createTimes.length;i++){
                    array.push(this.format(new Date(this.queryModel.createTimes[i]),"yyyy-MM-dd 00:00:00"))
                }
            }
            let json=localStorage.getItem("member");
            if(json==null||json=="null"){
                memberGet({},(res)=>{
                    this.member=res.Result;
                    localStorage.setItem("member",JSON.stringify(res.Result));
                })
            }else{
                this.member=JSON.parse(json);
            }

            if(this.queryModel.createTimes==undefined||this.queryModel.createTimes==0){
                this.$message({
                    message: '请选择一个时间范围',
                    type: 'warning'
                });
                return;
            }
            window.open(uri+"/Account/GetExl?shopId="+(this.queryModel.shopId==undefined?0:this.queryModel.shopId)+"&queryTxt="+this.queryModel.queryTxt+"&type="+(this.queryModel.type==undefined?0:this.queryModel.type)+"&createTimes="+(array.length==0?"[]":JSON.stringify(array))
           +"&memberId="+this.member.Id);
        },
        announcementGetAction(pageIndex){
            this.isLoading=true;

            // recordGet({
            //     pIndex:pageIndex,
            //     pSize:this.pagination.pageSize,
            //     queryTxt:this.queryModel.queryTxt,
            //     shopId:this.queryModel.shopId==undefined?0:this.queryModel.shopId,
            //     type:this.queryModel.type==undefined?0:this.queryModel.type,
            //     createTimes:array.length==0?"[]":JSON.stringify(array)
            // },(res)=>{
            //     this.recordList=res.Result;
            //     this.pagination.total=res.TotalCount;
            //     this.pagination.current=res.PageIndex;
            //     this.isLoading=false;
            // })
            announcementGet({
                pIndex:pageIndex,
                pSize:this.pagination.pageSize,
                queryTxt:''
            },(res)=>{
                this.announcementList=res.Result;
                this.pagination.total=res.TotalCount;
                this.pagination.current=res.PageIndex;
                this.isLoading=false;
            });
        },
        to(path){
            this.$router.push(path)
        },
        filterOption(input, option) {
            return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        handleTableChange(e){
            this.announcementGetAction(e.current);
        },
        format (date,fmt) {
            let o = {
                "M+": date.getMonth() + 1,         //月份
                "d+": date.getDate(),          //日
                "h+": date.getHours(),          //小时
                "m+": date.getMinutes(),         //分
                "s+": date.getSeconds(),         //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                "S": date.getMilliseconds()       //毫秒
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            }
            for (let k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                }
            }
            return fmt;
        }
    },
    created(){
    },
    mounted() {
        this.announcementGetAction(1);
        shopGetSel({},(res)=>{
            this.shopList=res.Result;
        })
    }
};
</script>
<style scoped>

.queryDiv{
    margin-top:20px;
    margin-bottom:20px;
    margin-right:20px;
    margin-left:20px
}
.queryTxt{
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #0d0c22;
}
</style>